@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&amp;display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo&amp;display=swap');
@import url('fontiran.css');


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /*font-family: 'IRANSans', Cairo, sans-serif;*/
    /*font-family: Cairo, sans-serif !important;*/

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

nav,
h1,
h2,
h3,
h4,
h5,
h6,
.chakra-heading,
label,
input,
select,
textarea,
th,
button,
.chakra-button,
[class^="chakra-radio"] *,
[id^="react-select"],
.amchart-text{
    font-family: BlinkMacSystemFont, Cairo, 'IRANSans', sans-serif !important;
}

.chakra-link {
    line-height: normal;
}

.chakra-text {
    font-family: BlinkMacSystemFont, 'IRANSans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auto-dir-text {
    direction: ltr;
}

.auto-dir-text:lang(ar) {
    direction: rtl !important;
}

/* For WebKit (Chrome, Safari) and newer versions of Edge */
.accented-scrollbar::-webkit-scrollbar {
    width: 12px;
    background-color: transparent; /* Make the background color of the scrollbar track transparent */
}

.accented-scrollbar::-webkit-scrollbar-thumb {
    background-color: #f00; /* Make the background color of the scrollbar thumb transparent */
}

.accented-scrollbar::-webkit-scrollbar-track {
    background-color: #9C1A1C;
}

/* For Firefox */
.accented-scrollbar * {
    scrollbar-width: thin;
    scrollbar-color: #f00 #9C1A1C;
}

/* For WebKit (Chrome, Safari) and newer versions of Edge */
.transparent-scrollbar::-webkit-scrollbar {
    width: 12px;
    background-color: transparent; /* Make the background color of the scrollbar track transparent */
}

.transparent-scrollbar::-webkit-scrollbar-thumb,
.transparent-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

/* For Firefox */
.transparent-scrollbar * {
    scrollbar-width: thin;
    scrollbar-color: #f00 #9C1A1C;
}

.trumbowyg-box .trumbowyg-editor {
    direction: rtl;
}

.trumbowyg-editor {
    font-family: 'IRANSans', Cairo, sans-serif;
}
.tooltips,
.tooltips *,
.articles-font *,
.trumbowyg-editor * {
    font-family: 'IRANSans', Cairo, sans-serif !important;
}

.article-cats a {
    margin: 5px;
}
.react-datepicker-wrapper {
  width: 100%;
}