.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
.bottom-text-box{
    opacity: 0.9;
    width: '100%';
    transition: opacity 0.3s ease;
}

.bottom-text-box:hover {
    opacity: 0.5;
}