.loading {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.loading .loading-activity {
    display: block;
    position: fixed;
    top: 35%;
    right: 0;
    width: 150px;
    height: 430px;
    background: #29d;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    -webkit-transform: translateX(200%) translateY(-100%) rotate(45deg);
    transform: translateX(200%) translateY(-100%) rotate(45deg);
    pointer-events: none;
}

.loading.loading-active .loading-activity {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
    transform: translateX(50%) translateY(-50%) rotate(45deg);
}

.loading .loading-activity::before,
.loading .loading-activity::after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    bottom: 30px;
    left: 50%;
    display: block;
    border: 5px solid #fff;
    border-radius: 50%;
    content: '';
}

.loading .loading-activity::before {
    margin-left: -40px;
    width: 80px;
    height: 80px;
    border-right-color: rgba(0, 0, 0, .2);
    border-left-color: rgba(0, 0, 0, .2);
    -webkit-animation: loading-theme-corner-indicator-spin 3s linear infinite;
    animation: loading-theme-corner-indicator-spin 3s linear infinite;
}

.loading .loading-activity::after {
    bottom: 50px;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    border-top-color: rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .2);
    -webkit-animation: loading-theme-corner-indicator-spin 1s linear infinite;
    animation: loading-theme-corner-indicator-spin 1s linear infinite;
}

@-webkit-keyframes loading-theme-corner-indicator-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
    }
}

@keyframes loading-theme-corner-indicator-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}