@import url('https://fonts.googleapis.com/css?family=Cairo:400,700&display=swap');

.jq-cloud-container {
    position: absolute;
    left: 0;
    top: 0;
}

#jq-cloud {
    position: relative;
    font-size: 13px;
    font-weight: 400;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    padding: 2px 7px;
    /* background: transparent; */
    pointer-events: auto;
    color: #fff;
    margin: 0;
    width: 900px;
    height: 700px;

    opacity: 0.9;
    transform: translate3d(502px, 91px, 0px);
}

#jq-cloud span {
    cursor: pointer;
    font-family: 'Cairo' !important;
    font-weight: 700;
    /*padding: 0 2px !important;*/
    padding: 2px !important;
    background: rgba(115, 0, 118, 0.8);
    color: #fff !important;
    border-radius: 4px;
    /*line-height: 21px !important;*/
}